import React, { useState, useEffect } from "react";
import { navigate, Link } from "gatsby";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import Select from "react-select";
import { ThemeProvider } from "@material-ui/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import { occasioMaterialTheme } from "../../components/mui/muiUtils";
import PlainPage from "../../templates/plain-page";
import {
  firebaseApp,
  gglSignup,
  fbSignup,
  phoneAuthCredential,
  recaptchaVerifier,
  db,
  storageRef,
} from "../../components/firebase/firestore";
import Modal from "../../components/Modal";
import placeholder128 from "../../../static/img/placeholder-128x128.png";
import ImageTools from "../../utils/imageTools";
import {
  isValidDate,
  getCountriesList,
  getSectorList,
  getLanguagesList,
  getAccountTypeList,
  MakeRequired,
  isFullAge,
  isInviteOnlyMode,
} from "../../utils/utils";

const makeStep = nextStep => {
  const prevStepElem = document.getElementById(`step-marker-${nextStep - 1}`);
  prevStepElem.classList.remove("is-active");
  prevStepElem.classList.add("is-completed");
  document.getElementById(`step-marker-${nextStep}`).classList.add("is-active");

  document.getElementById(`step-content-${nextStep - 1}`).style.display =
    "none";
  document.getElementById(`step-content-${nextStep}`).style.display = "block";
  // scroll to the top, since next step may be vertically short and user don't have to scroll manually
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const Content = ({ currentUser, refUid, rereadRefUid }) => {
  const [cvFile, setCvFile] = useState({ name: "" });
  const [avatarFile, setAvatarFile] = useState();
  const countiesList = getCountriesList();
  const [selectedCountry, setSelectedCountry] = useState();
  const sectorList = getSectorList();
  const [selectedSector, setSelectedSector] = useState();
  const languagesList = getLanguagesList();
  const [selectedLangs, setSelectedLangs] = useState();
  const typeList = getAccountTypeList();
  const [selectedType, setSelectedType] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [userInitializing, setUserInitializing] = useState(false);

  const [refUserName, setRefUserName] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalCb, setModalCb] = useState({
    submit: () => {},
    deny: () => {
      setModalOpen(false);
    },
  });

  useEffect(() => {
    if (isInviteOnlyMode() && !refUid) {
      navigate("/get-invited");
    }
  }, [refUid]);

  useEffect(() => {
    window.recaptchaVerifier = recaptchaVerifier("recaptcha-container");

    // hide other notifcations
    document.getElementById("notifications_panel").style.display = "none";
    // add legal notification
    document.getElementById("signup_legal_notice").style.display = "block";

    // ref link user
    const { localStorage } = window;
    // const refUid = localStorage.getItem("ref_uid");
    if (refUid) {
      const userRef = db.collection("users").doc(refUid);
      userRef.get().then(doc => {
        if (doc.exists) {
          const { first_name: firstName, surname } = doc.data();

          setRefUserName(
            firstName && surname ? `${firstName} ${surname}` : "anonymous user",
          );
        } else {
          setRefUserName(false);
          localStorage.removeItem("ref_uid");
          rereadRefUid();
        }
      });
    }
  }, [refUid, rereadRefUid]);

  useEffect(() => {
    // Hook used in order to catch the case when user created before userInitializing is changed that caused redirect to /settings, instead of continuing sign up steps. No need to monitor currentUser change, it's handled in PlainPage
    // only happens if user already logged in when loading the page. Redirects after sign up handled individually
    if (currentUser && !userInitializing) {
      navigate("/settings");
    }
  }, [currentUser, userInitializing]);

  // reauthenticate user before email/phone/password change (google policy)
  const reAuthUser = (parentFunc, event) => {
    setModalCb({
      ...modalCb,
      submit: async e => {
        const password = e.target.elements["current-password-field"].value;
        const credential = emailAuthCredential(currentUser.email, password);
        await currentUser
          .reauthenticateWithCredential(credential)
          .then(() => {
            setModalOpen(false);
            parentFunc(event);
          })
          .catch(error => {
            if (error.code === "auth/wrong-password") {
              alert("Wrong password. Try again");
            } else {
              alert(`Error reauthenticating. ${error.message}`);
            }
          });
      },
    });
    setModalOpen(true);
  };

  const step1 = async e => {
    e.preventDefault();

    const button = document.getElementById("step1-next-btn");
    button.classList.add("is-loading");

    const { email, password } = e.target.elements;

    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email.value, password.value)
      .then(() => {
        setUserInitializing(true);
        makeStep(2);
      })
      .catch(error => {
        alert(`Error signing up. ${error.message}`);
      })
      .finally(() => {
        button.classList.remove("is-loading");
      });
  };

  const step2 = e => {
    e.preventDefault();

    const button = document.getElementById("step2-next-btn");
    button.classList.add("is-loading");

    const newPhone = document.querySelector("#phone-field").value;

    const phoneParsed = parsePhoneNumberFromString(newPhone);
    if (!(phoneParsed && phoneParsed.isValid())) {
      alert(
        "Phone number is invalid. Please make sure you follow the required format.",
      );
      button.classList.remove("is-loading");
      return;
    }

    const appVerifier = window.recaptchaVerifier;

    firebaseApp
      .auth()
      .signInWithPhoneNumber(newPhone, appVerifier)
      .then(async confirmationResult => {
        window.confirmationResult = confirmationResult;

        // otherwise captcha will rerender and cause prompt to disappear
        // TODO find better solution
        document.querySelector("#recaptcha-container").innerHTML = "";

        const code = prompt(
          "Please enter code from SMS sent to your phone.",
          "",
        );
        if (code !== null) {
          if (currentUser.phoneNumber) {
            await currentUser.unlink("phone");
          }
          const credential = phoneAuthCredential(
            confirmationResult.verificationId,
            code,
          );

          await currentUser.linkWithCredential(credential);
          makeStep(3);
        }
      })
      .catch(error => {
        window.recaptchaVerifier.render().then(widgetId => {
          grecaptcha.reset(widgetId);
        });
        if (error.code === "auth/requires-recent-login") {
          reAuthUser(handlePhoneSubmission, e);
        } else {
          alert(`Error verifying phone number. ${error.message}`);
        }
      })
      .finally(() => {
        window.recaptchaVerifier = recaptchaVerifier("recaptcha-container");
        button.classList.remove("is-loading");
      });
  };

  const handleCVSelect = e => {
    const fileTemp = e.target.files[0];
    if (fileTemp && fileTemp.size) {
      if (fileTemp.size > 5000000) {
        alert("Please select file smaller than 5 Mb");
        return;
      }

      if (fileTemp.type !== "application/pdf") {
        alert("Wrong file type. Only PDF files are supported.");
        return;
      }

      setCvFile(fileTemp);
    }
  };

  const handleAvatarSelect = e => {
    const fileTemp = e.target.files[0];
    if (fileTemp && fileTemp.size) {
      if (!["image/png", "image/jpeg"].includes(fileTemp.type)) {
        alert("Wrong file type. Only JPG and PNG files are supported.");
        return;
      }

      ImageTools.resize(
        fileTemp,
        {
          width: 256, // maximum width
          height: 256, // maximum height
        },
        (blob, didItResize) => {
          // didItResize will be true if it managed to resize it, otherwise false (and will return the original file as 'blob')
          document.getElementById(
            "avatar-preview",
          ).src = window.URL.createObjectURL(blob);

          setAvatarFile(blob);
        },
      );
    }
  };

  const step3 = e => {
    e.preventDefault();

    // check for valid date (regexp doesn't cover all cases)
    const date = document.querySelector("input[name=birth]").value;
    if (date && !isValidDate(date)) {
      alert("Wrong date. Please enter existing birth date.");
      return;
    }

    if (date && isFullAge(date)) {
      alert(
        "You must be at least 18 years old to use this site. Please consult our terms and conditions.",
      );
      return;
    }

    const button = document.getElementById("step3-next-btn");
    button.classList.add("is-loading");

    const data = [
      ...document.querySelectorAll(
        `#${e.target.id} input[type=text], #${e.target.id} input[type=url], #${e.target.id} input[type=hidden]`,
      ),
    ]
      .filter(elem => elem.name)
      .reduce((accumulator, currentValue) => {
        if (accumulator.hasOwnProperty(currentValue.name)) {
          if (Array.isArray(accumulator[currentValue.name])) {
            accumulator[currentValue.name].push(currentValue.value);
          } else {
            accumulator[currentValue.name] = [
              accumulator[currentValue.name],
              currentValue.value,
            ];
          }
        } else {
          accumulator[currentValue.name] = currentValue.value;
        }
        return accumulator;
      }, {});

    // add CV filename for reference to Firebase Storage, since we can't get it from there
    if (cvFile.name) {
      data.cv = cvFile.name;
    }

    const userRef = db.collection("users").doc(currentUser.uid);
    userRef
      .update(data)
      .then(async () => {
        // CV upload
        if (cvFile.size) {
          const cvRef = storageRef.child(
            `cv/${currentUser.uid}/${cvFile.name}`,
          );
          await cvRef.put(cvFile);
          // this way old user files are not deleted
        }
        // Avatar upload
        if (avatarFile) {
          const avatarRef = storageRef.child(`avatar/${currentUser.uid}`);
          await avatarRef.put(avatarFile);
        }
        makeStep(4);
      })
      .catch(error => {
        alert(`Error saving data. ${error.message}`);
      })
      .finally(() => button.classList.remove("is-loading"));
  };

  const step4 = e => {
    e.preventDefault();
    navigate("/settings");
  };

  return (
    <div className="column is-6-tablet is-5-desktop is-4-widescreen">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Register
      </h2>
      {refUserName ? (
        <h4 className="title is-size-5 has-text-weight-bold is-bold-light">
          You've been invited by {refUserName}
        </h4>
      ) : (
        ""
      )}

      <div className="box">
        {/* CSS from bulma-steps, JS is self-made */}
        <div className="steps" id="signupSteps">
          <div id="step-marker-1" className="step-item is-active">
            <div className="step-marker">1</div>
            <div className="step-details">
              <p className="step-title">Account</p>
            </div>
          </div>
          <div id="step-marker-2" className="step-item">
            <div className="step-marker">2</div>
            <div className="step-details">
              <p className="step-title">Phone</p>
            </div>
          </div>
          <div id="step-marker-3" className="step-item">
            <div className="step-marker">3</div>
            <div className="step-details">
              <p className="step-title">Profile</p>
            </div>
          </div>
          <div id="step-marker-4" className="step-item">
            <div className="step-marker">4</div>
            <div className="step-details">
              <p className="step-title">Finish</p>
            </div>
          </div>{" "}
        </div>
        <div id="step-content-1">
          <form action="" onSubmit={step1}>
            <div className="field">
              <label htmlFor="" className="label">
                Email
              </label>
              <div className="control has-icons-left">
                <input
                  type="email"
                  placeholder="e.g. jonhdoe@gmail.com"
                  className="input"
                  name="email"
                  autoComplete="email"
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-envelope" />
                </span>
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Password
              </label>
              <div className="control has-icons-left">
                <input
                  type="password"
                  placeholder="*******"
                  className="input"
                  name="password"
                  autoComplete="new-password"
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-lock" />
                </span>
              </div>
            </div>
            <div className="field is-grouped is-grouped-right">
              <button id="step1-next-btn" className="button is-success">
                Register
              </button>
            </div>
          </form>
          <div className="is-divider" data-content="OR" />
          <div className="columns has-text-centered">
            <div className="column">
              <div className="field">
                <a
                  id="ggl-signup"
                  className="button is-black"
                  style={{ backgroundColor: "#dd4b39" }}
                  onClick={gglSignup}
                >
                  <span className="icon">
                    <i className="fab fa-google" />
                  </span>
                  <span>Register with Google</span>
                </a>
              </div>
              <div className="field">
                <a
                  id="fb-signup"
                  className="button is-black"
                  style={{ backgroundColor: "#3b5998" }}
                  onClick={fbSignup}
                >
                  <span className="icon">
                    <i className="fab fa-facebook-f" />
                  </span>
                  <span>Register with Facebook</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="step-content-2" style={{ display: "none" }}>
          <form action="" onSubmit={step2}>
            <div className="field">
              <label htmlFor="" className="label">
                Phone number
              </label>
              <div className="control has-icons-left is-expanded">
                <input
                  id="phone-field"
                  type="tel"
                  placeholder="+18001234567"
                  className="input"
                  name="phone"
                  autoComplete="tel"
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-phone" />
                </span>
              </div>
              <p className="help">
                <i>format: +18001234567</i>
              </p>
            </div>
            <div className="field is-grouped is-grouped-right">
              <button id="step2-next-btn" className="button is-success">
                Verify
              </button>
            </div>
            <div id="recaptcha-container" />
          </form>
        </div>

        <div id="step-content-3" style={{ display: "none" }}>
          <form id="profile-form" action="" onSubmit={step3}>
            <div className="field">
              <label htmlFor="" className="label">
                Profile picture
              </label>
              <span className="label">
                <figure className="image is-cropped-64x64 ">
                  <img
                    id="avatar-preview"
                    className="is-rounded"
                    src={placeholder128}
                  />
                </figure>
              </span>
              <div className="control">
                <div className="file ">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      name="avatar"
                      onChange={handleAvatarSelect}
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload" />
                      </span>
                      <span className="file-label">
                        Choose a file (JPG and PNG only)
                      </span>
                    </span>

                    {/* <span className="file-name">{avatarFile.name}</span> */}
                  </label>
                </div>

                {/* <MakeRequired required={!cvFile.name} /> */}
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                First name<span className="has-text-danger"> *</span>
              </label>
              <div className="control">
                <input
                  type="text"
                  placeholder="Jane"
                  className="input"
                  name="first_name"
                  autoComplete="given-name"
                  required
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Surname<span className="has-text-danger"> *</span>
              </label>
              <div className="control">
                <input
                  type="text"
                  placeholder="Smith"
                  className="input"
                  name="surname"
                  autoComplete="family-name"
                  required
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Date of Birth
              </label>
              <div className="control">
                <ThemeProvider theme={occasioMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <DatePicker
                      name="birth"
                      value={dateOfBirth}
                      onChange={setDateOfBirth}
                      format="DD-MM-YYYY"
                      openTo="year"
                      disableFuture
                      views={["year", "month", "date"]}
                      maxDate={dayjs().subtract(18, "year")}
                      maxDateMessage="You must be at least 18 years old"
                      fullWidth
                      inputVariant="outlined"
                      autoOk
                      placeholder="Choose date of birth"
                      okLabel=""
                      cancelLabel=""
                      // required
                      inputProps={{ readOnly: false }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                CV
              </label>
              <div className="control">
                <div className="file has-name is-fullwidth">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      accept=".pdf"
                      name="cv"
                      onChange={handleCVSelect}
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload" />
                      </span>
                      <span className="file-label">
                        Choose a file (PDF only)
                      </span>
                    </span>
                    <span className="file-name">{cvFile.name}</span>
                  </label>
                </div>
                {/* <MakeRequired required={!cvFile.name} /> */}
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Languages
              </label>
              <div className="control">
                <Select
                  isClearable={false}
                  isSearchable
                  isMulti
                  name="languages"
                  options={
                    selectedLangs && Object.keys(selectedLangs).length >= 5
                      ? selectedLangs
                      : languagesList
                  }
                  onChange={values => {
                    setSelectedLangs(values);
                  }}
                  value={selectedLangs}
                  noOptionsMessage={() =>
                    "You can't choose more than 5 languages"
                  }
                />
                {/* <MakeRequired required={!selectedLangs} /> */}
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Sector
              </label>
              <div className="control">
                <Select
                  isClearable={false}
                  isSearchable
                  name="sector"
                  options={sectorList}
                  onChange={values => {
                    setSelectedSector(values);
                  }}
                  value={selectedSector}
                />
                {/* <MakeRequired required={!selectedSector} /> */}
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Institution/Organisations name
              </label>
              <div className="control">
                <input
                  type="text"
                  placeholder="Corporation inc."
                  className="input"
                  name="organization"
                  // required
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Institution/Organisations website
              </label>
              <div className="control">
                <input
                  type="url"
                  placeholder="example.com"
                  className="input"
                  name="website"
                  // required
                />
                <p className="help">
                  <i>format: http://example.com</i>
                </p>
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Profile headline
              </label>
              <div className="control">
                <input
                  type="text"
                  placeholder="e.g. Minister of Finance or Final Year Law Student"
                  className="input"
                  name="headline"
                  // required
                />
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Country<span className="has-text-danger"> *</span>
              </label>
              <div className="control">
                <Select
                  isClearable={false}
                  isSearchable
                  name="country"
                  options={countiesList}
                  onChange={values => {
                    setSelectedCountry(values);
                  }}
                  value={selectedCountry}
                />
                <MakeRequired required={!selectedCountry} />
              </div>
            </div>
            <div className="field">
              <label htmlFor="" className="label">
                Account type<span className="has-text-danger"> *</span>
              </label>
              <div className="control">
                <Select
                  name="type"
                  options={typeList}
                  onChange={values => {
                    setSelectedType(values);
                  }}
                  value={selectedType}
                />
                <MakeRequired required={!selectedType} />
              </div>
            </div>
            <div className="field is-grouped is-grouped-right">
              <button id="step3-next-btn" className="button is-success">
                Next
              </button>
            </div>
          </form>
        </div>

        <div id="step-content-4" style={{ display: "none" }}>
          <form action="" onSubmit={step4}>
            <label htmlFor="" className="label">
              Congratulations! Your account is created.
            </label>
            Please follow the instructions in the email we sent you.
            <div className="field is-grouped is-grouped-right">
              <button id="step4-next-btn" className="button is-success">
                Finish
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="has-text-centered">
        Access and use of this site is strictly limited to people over the age
        of 18 years of age. By creating an account and using the site, you are
        attesting you are at least 18 years of age and agree to our{" "}
        <Link to="/terms">Terms&nbsp;&&nbsp;Conditions</Link>
        .
        <br />
        <br />
        To find out what personal data we collect and how we use it, please
        visit our <Link to="/privacy">Privacy Policy</Link>.
        {/* <br />
        <br />
        OCCASIO is a trusted network of connected people and so to keep it a
        safe place, we have a real data policy which means users must use real
        names, real information and hold a single account. We review accounts
        periodically and duplicate accounts, or accounts with inaccurate
        information or that breach our terms and conditions will lose rewards,
        be terminated and banned without notice. */}
      </div>
      <Modal idPrefix="password-modal" isOpen={modalOpen} cb={modalCb}>
        <label htmlFor="">
          For security reasons, provide your current password.
        </label>
        <label htmlFor="" className="label">
          Current password
        </label>
        <div className="field">
          <div className="field has-addons ">
            <div className="control is-expanded">
              <input
                id="current-password-field"
                type="password"
                minLength="6"
                className="input"
                name="password"
                autoComplete="current-password"
                required
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const SignupPage = () => {
  return <PlainPage Content={Content} isCentred />;
};

export default SignupPage;
